import { ReactElement } from 'react'
import { MainLayout } from 'components/Layout/main-layout'
import { ShowWorkspace } from 'containers/Workspace/show'

import { withAuthenticationRequired } from '@auth0/auth0-react'

const Page = () => {
  return <ShowWorkspace />
}

const pageWithAuth = withAuthenticationRequired(Page) as any

pageWithAuth.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>
}

export default pageWithAuth
